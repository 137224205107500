<template>
    <div class="card firstcard">
        <p class="Loading" :style="{ position: 'absolute', 'z-index': '-99', 'margin-top': '1em', 'font-size': 'medium' }">
            Loading...</p>
        <div class="modelkey card" v-for="(names, Domain) in dataE" :key="Domain">
            <div class="grid col-12" :style="{ 'margin-left': '10px' }">
                <div class="col-2">
                    <h5>{{ names.name }}</h5>
                </div>
                <div class="field col-7">
                    <section class="Userdetail" v-if="Usermanag[names.domain]">
                        <label>Email&nbsp;<span :style="{ color: 'red' }">*</span>&nbsp;</label>
                        <InputText id="customerDomain" v-model="customerName" type="text" />
                        <input type="checkbox" :id="'isAdmin'" />
                        <label :for="'isAdmin'">Is Admin</label>
                        <Button label=" submit" @click="SubmitUser(names.domain, names.name)"
                            class="p-button-sm p-button-success" :style="{ 'margin-left': '1em' }" />
                    </section>
                </div>
                <div class="col-2">
                    <Button label="Add User" @click="AddUser(names.domain)" :style="{ float: 'right' }"
                        class="p-button-sm" />
                </div>
                <div class="col-1">
                    <Button class="showhide"
                        :icon="this.toggledisplay[names.domain] ? 'pi pi-angle-up' : 'pi pi-angle-down'"
                        @click="Showhide(names)" :style="{ float: 'right' }" />
                </div>
                <div v-if="loadingvalue[names.domain]" :style="{ 'margin-left': '1em', width: '90%' }">
                    <i class="pi pi-spin pi-spinner" style="font-size: 1em; float: left"></i>
                    <p class="Loading"><i>&nbsp;Fetching users, please wait...</i></p>
                </div>
            </div>
            <div v-if="toggledisplay[names.domain]" class="grid toggleit">
                <section class="modelnames card col-10 grid" v-for="(user_data, index) in individualUsers[names.domain]"
                    :key="index">
                    <!--<div class="modelnamessub">-->
                    <span class="col-3">{{ user_data.name }}</span>
                    <span class="col-4">{{ user_data.email }}</span>
                    <span class="col-2"> <span v-show="user_data.isAdmin">Admin</span></span>
                    <span class="col-3"><Button label="Remove" @click="RemoveUser(user_data.email, user_data.domain)"
                            :style="{ float: 'right' }" class="p-button-sm p-button-warning" /></span>
                    <!--</div>-->
                </section>
            </div>
            <br />
        </div>
    </div>
</template>
<script>
import AdminService from '../tipureServices/AdminService';
import FormulatorService from '../tipureServices/FormulatorService';
export default {
    data() {
        return {
            customerName: null,
            isAdmin: false,
            dataE: [],
            toggledisplay: {},
            Usermanag: {},
            individualUsers: {},
            loadingvalue: {},
        };
    },
    async created() {
        this.adminService = new AdminService();
        this.formulatorService = new FormulatorService();
        await this.adminService
            .getCustomerDomainList()
            .then((data) => {
                let datatemp = data.data;
                for (let i in datatemp) {
                    if (datatemp[i].domain.toLowerCase() === 'chemourscom') {
                        continue;
                    } else {
                        this.dataE.push(datatemp[i]);
                    }
                }
                for (let j in this.dataE) {
                    this.toggledisplay[this.dataE[j].domain] = false;
                    this.Usermanag[this.dataE[j].domain] = false;
                }
            })
            .catch((err) => {
                this.$toast.add({ severity: 'error', summary: 'INTERNAL SERVER ERROR', detail: err, life: 5000 });
            });
    },
    methods: {
        AddUser(domain) {
            if (this.Usermanag[domain]) {
                this.Usermanag[domain] = false;
            } else {
                this.Usermanag[domain] = true;
            }
        },
        async RemoveUser(email, domain) {
            this.loadingvalue[domain] = true;
            let maildomain = email.split("@")[1];
            await this.adminService
                .removeUser(email)
                .then((result) => {
                    console.log(result);

                    this.$toast.add({ severity: 'success', summary: 'User access removed', life: 5000 });
                    this.RefreshUserList(domain, maildomain);
                })
                .catch((err) => {
                    console.log(err);
                    this.loadingvalue[domain] = false;
                    this.$toast.add({ severity: 'error', summary: 'Error removing user access.', life: 5000 });
                });
        },
        async SubmitUser(domain, name) {

            if (this.customerName.split('@')[1].toLowerCase().replaceAll(".", "").includes(domain)) {
                let maildomain = this.customerName.split("@")[1];
                if (this.customerName) {
                    this.loadingvalue[domain] = true;

                    await this.formulatorService
                        .enroleUser(this.customerName, this.isAdmin)
                        .then((data) => {
                            if (data) {

                                this.loading_value = false;
                                this.customerName = '';
                                this.Usermanag[domain] = !this.Usermanag[domain];
                                console.log(data);
                                /*setTimeout(() => {
                                    this.$router.push({ path: '/admin/listUser' });
                                }, 500);*/
                                this.$toast.add({ severity: 'success', summary: 'New user added. It may take a few seconds to be available in our system.', life: 5000 });
                                this.RefreshUserList(domain, maildomain);

                            }
                        })
                        .catch((err) => {

                            this.$toast.add({ severity: 'error', summary: 'INTERNAL SERVER ERROR', detail: err, life: 5000 });
                            this.loading_value = false;
                        });
                } else {
                    alert('Please enter the UserID/EmailID');
                }
            } else {
                alert('Entered e-mailID does not match the domain ' + name);
            }
        },
        async Showhide(customer) {
            this.toggledisplay[customer.domain] = !this.toggledisplay[customer.domain];
            if (!this.individualUsers[customer.domain]) {
                this.loadingvalue[customer.domain] = !this.loadingvalue[customer.domain];
                await this.adminService
                    .geteUsersList(customer.maildomain)
                    .then((data) => {
                        this.individualUsers[customer.domain] = data.users;

                        this.loadingvalue[customer.domain] = false;
                    })
                    .catch((err) => {
                        this.$toast.add({ severity: 'error', summary: 'INTERNAL SERVER ERROR', detail: err, life: 5000 });
                        this.loadingvalue[customer.domain] = false;
                    });
            }
        },
        async RefreshUserList(domain, maildomain) {

            this.toggledisplay[domain] = false;
            this.loadingvalue[domain] = true;

            await this.adminService
                .geteUsersList(maildomain)
                .then((data) => {
                    this.individualUsers[domain] = data.users;
                    this.loadingvalue[domain] = false;

                })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'INTERNAL SERVER ERROR', detail: err, life: 5000 });
                    this.loadingvalue[domain] = false;
                });

        }
    },
};
</script>

<style scoped>
.firstcard {
    margin-left: 1em;
    background-color: rgb(238, 238, 238);
}

.modelnames {
    margin-left: 4em;
    margin-top: 0.3em;
    margin-bottom: 1em;
}

.modelnamessub {
    margin-left: 4em;
    margin-top: 0.3em;
    margin-bottom: 1em;
}

.modelnames:hover {
    background-color: rgb(241, 241, 241);
    font-size: larger;
}

.modelkey {
    margin: 0.1em;
    padding: 1px;
}

.subtextleft {
    margin-left: 1em;
    font-weight: 500;
    font-size: medium;
}

.subtextright {
    float: right;
    margin-right: 1em;
    font-size: small;
}

#customerDomain {
    width: 65%;
    border: none;
    border-bottom: 1px solid rgb(190, 189, 189);
}

.Headingname {
    background-color: rgb(232, 232, 232);
}

.showhide {
    background-color: transparent;
    font-size: larger;
    float: left;
    padding: 0.3em;
    padding-left: 0.5em;
    border: none;
    border-radius: 100%;
    color: rgb(8, 8, 8);
}

.showhide:hover {
    background-color: rgb(244, 244, 244);
    color: rgb(42, 42, 42);
}

.Loading {
    color: rgb(142, 143, 143);
    font-weight: 400;
    font-size: smaller;
}
</style>