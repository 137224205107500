<template>
    <div class="card firstcard">
        <p class="Loading"
            :style="{ 'position': 'absolute', 'z-index': '-99', 'margin-top': '1em', 'font-size': 'medium' }">
            Loading...
        </p>

        <div v-if="processing"
            style="z-index: 9999;position: fixed;width: 100%;height: 100%;display:flex;align-content: center;">
            <ProgressSpinner />
        </div>

        <div class="modelkey card" v-for="(names, Domain) in userList" :key="Domain">

            <div class="grid col-12" :style="{ 'margin-left': '10px' }">
                <div :style="{ 'margin-left': '1em', width: '90%' }">

                </div>
                <div class="col-2">
                    <!-- <h5>{{Domain.toUpperCase()}}</h5> -->
                    <h5>Chemours</h5><i v-if="processing" class="pi pi-spin pi-spinner"
                        style="font-size: 1em; float: left"></i>
                    <p v-if="processing" class="Loading"><i>&nbsp;Processing Request...</i></p>
                </div>
                <div class="field col-7">
                    <section class="Userdetail" v-if="showAddUserSection">
                        <label>Email&nbsp;<span :style="{ color: 'red' }">*</span>&nbsp;</label>
                        <InputText id="customerDomain" v-model="customerName" type="text" />

                        <input type="checkbox" :id="'isAdmin'" v-model="isAdmin" />
                        <label :for="'isAdmin'">Is Admin</label>
                        <Button label=" submit" @click="SubmitRequest()" class="p-button-sm p-button-success"
                            :style="{ 'margin-left': '1em' }" />
                    </section>
                </div>
                <div class="col-2">
                    <Button label="Add User" @click="AddUser()" :style="{ 'float': 'right' }" class="p-button-sm" />
                </div>
                <div class="col-1">
                    <Button class="showhide" :icon="this.toggledisplay[Domain] ? 'pi pi-angle-up' : 'pi pi-angle-down'"
                        @click="Showhide(Domain)" :style="{ 'float': 'right' }" />
                </div>
            </div>
            <div v-if="toggledisplay[Domain]" class="grid toggleit">
                <section class="modelnames card col-10 grid" v-for="(user_data, index) in names" :key="index">
                    <!--<div class="modelnamessub">-->
                    <span class="col-3">{{ user_data.name }}</span>
                    <span class="col-4">{{ user_data.email }}</span>
                    <span class="col-2"> <span v-show="user_data.isAdmin">Admin</span></span>
                    <span class="col-3"><Button label="Remove" @click="RemoveUser(user_data.email, index)"
                            :style="{ float: 'right' }" class="p-button-sm p-button-warning" /></span>

                    <!--</div>-->
                </section>
                <!---- <section class="modelnames card col-10" v-for="(user_data,index) in names" :key="index">
                <div class="modelnamessub">
                    <span class="subtextleft">{{user_data.name}}</span>
                    <span class="subtextright">{{user_data.email}}</span>
                </div>
            </section>-->
            </div>
        </div>
    </div>
</template>
<script>
import AdminService from '../tipureServices/AdminService';
import FormulatorService from '../tipureServices/FormulatorService';
export default {

    data() {
        return {
            customerName: null,
            isAdmin: false,
            userList: null,
            toggledisplay: {},
            Usermanag: {},
            showAddUserSection: false,
            processing: false
        }
    },
    async created() {
        this.adminService = new AdminService();
        this.formulatorService = new FormulatorService();
        await this.adminService.getInternalUsersList().then((data) => {

            this.userList = data;

            for (let j in this.userList) {
                this.toggledisplay[j] = false;
                this.Usermanag[j] = false
            }
        }).catch(err => {
            this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
        });
    },

    methods: {
        AddUser() {
            this.showAddUserSection = !this.showAddUserSection;

        },

        async SubmitRequest() {
            this.processing = true;
            await this.SubmitUser();

            this.processing = false;
        },
        async SubmitUser() {
            this.processing = true;
            //alert("Please wait while we add the user to the system");

            if (this.customerName.split('@')[1].toLowerCase().includes('chemours')) {

                if (this.customerName) {

                    await this.formulatorService.enroleUser(this.customerName, this.isAdmin).then((data) => {

                        if (data) {
                            this.loading_value = false;
                            this.customerName = "";
                            this.isAdmin = false;
                            this.showAddUserSection = false;
                            console.log(data);

                            this.$toast.add({ severity: 'success', summary: 'Added New user. It may take a few minutes to reflect in our systems.', life: 3000 });
                        }
                    }).catch(err => {

                        this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
                        this.loading_value = false;
                    });
                }
                else {
                    alert("Please enter the UserID/EmailID")
                }
            }
            else {
                alert("Entered e-mailID does not belong to Chemours")
            }
            this.processing = false;
        },

        Showhide(domain) {
            this.toggledisplay[domain] = !this.toggledisplay[domain]
        },
        changeicon(Domain) {
            if (this.toggledisplay[Domain]) {
                return 'pi-angle-up'
            }
            else {
                return 'pi-angle-down'
            }
        },


        async RemoveUser(email, domain) {
            this.processing = true;
            await this.adminService
                .removeUser(email)
                .then((result) => {
                    console.log(result);

                    this.$toast.add({ severity: 'success', summary: 'User access removed', life: 5000 });
                    this.RefreshUserList(domain);
                })
                .catch((err) => {
                    console.log(err);

                    this.$toast.add({ severity: 'error', summary: 'Error removing user access.', life: 5000 });
                });
            this.processing = false;
        },
        async RefreshUserList(domain) {
            this.processing = true;
            this.toggledisplay[domain] = false;
            this.loadingvalue[domain] = true;

            await this.adminService.getInternalUsersList().then((data) => {

                this.userList = data;
                console.log(this.userList);
                for (let j in this.userList) {
                    this.toggledisplay[j] = false;
                    this.Usermanag[j] = false
                }
            }).catch(err => {
                this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
            });
            this.processing = false;
        }
    }
}
</script>

<style scoped>
.firstcard {
    margin-left: 1em;
    background-color: rgb(238, 238, 238);
}

.modelnames {
    margin-left: 4em;
    margin-top: 0.3em;
    margin-bottom: 1em;
}

.modelnamessub {
    margin-left: 4em;
    margin-top: 0.3em;
    margin-bottom: 1em;
}

.modelnames:hover {
    background-color: rgb(241, 241, 241);
    font-size: larger;

}

.modelkey {
    margin: 0.1em;
    padding: 1px;
}

.subtextleft {
    margin-left: 1em;
    font-weight: 500;
    font-size: medium;
}

.subtextright {
    float: right;
    margin-right: 1em;
    font-size: small;
}

#customerDomain {
    width: 65%;
    border: none;
    border-bottom: 1px solid rgb(190, 189, 189);
}

.Headingname {
    background-color: rgb(232, 232, 232);
}

.showhide {
    background-color: transparent;
    font-size: larger;
    float: left;
    padding: 0.3em;
    padding-left: 0.5em;
    border: none;
    border-radius: 100%;
    color: rgb(8, 8, 8);
}

.showhide:hover {
    background-color: rgb(244, 244, 244);
    color: rgb(42, 42, 42);
}

.Loading {
    color: rgb(142, 143, 143);
    font-weight: 400;
}
</style>