<template>
    <Toast />
    <section>
        <Toolbar>
        <template #start>
            <h3>User Management</h3>
        </template>
    </Toolbar>
    <div class="toggle">
        <button class="toggleButon" @click="setslectedTab('externalUsers')" :class="slectedexButton">External Users</button>
        <button class="toggleButon" @click="setslectedTab('internalUsers')" :class="slectedinButton">Internal Users</button>
</div>
<div class="col-12" :style="{'margin':'0px','padding':'0px'}">
            <component :is="selectedTab"></component>
    </div>
    </section>
        <!-- <div class="card">
            <div class="grid col-12" v-for="(names,modelIndex) in IndividualUser.data" :key="modelIndex">
            <div class="card col-8" :style="{'margin':'1px','padding':'1px'}">
                <div class="modelvalue">
                    <p><i>&nbsp;{{names.name}}</i></p>
                </div>
            </div>
        </div> 
        </div> -->
    <div v-if="loading">
        <i class="pi pi-spin pi-spinner" style="font-size: 1.3em; float: left;"></i>
        <p class="Loading"><i>&nbsp;{{loading}}</i></p>
    </div>
</template>
<script>
// import AdminService from '../tipureServices/AdminService';
import internalUsers from '../tipureAdmin/internalUsers.vue'
import externalUsers from '../tipureAdmin/externalUsers.vue';
export default{
    data(){
        return{
            loading:null,
            IndividualUser: null,
            selectedTab: 'externalUsers',
        };
    },
    components: { internalUsers, externalUsers },
    // async created(){
    //     this.loading="Fetching Users, please wait...";
    //     this.adminService = new AdminService();
    //     await this.adminService.getCustomerDomainList().then((data) => {
    //         this.IndividualUser = data;
    //         this.loading = null;
    //         console.log("indisde");
    //     }).catch(err =>{
    //         this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
    //         this.loading=null;
    //     });
    // },
//    async mounted() {
//         // this.IndividualUser='data'
        
//      },
    provide(){
        return{
            dataI: this.IndividualUser,
            dataE:this.IndividualUser,
        }
     },
     computed:{
        slectedinButton(){
            return this.selectedTab === 'internalUsers' ? null : 'flat'
        },
        slectedexButton(){
            return this.selectedTab === 'externalUsers' ? null : 'flat' 
        }
     },
     methods:{
        setslectedTab(tab){
            this.selectedTab=tab;
        },
            
     }
}
</script>
<style scoped>
.modelkey{
    margin-top:10px ;
    padding-top:10px ;
    font-family:Arial, Helvetica, sans-serif;
}
.modelvalue{
    margin-left: 1em;
    margin-top: 2px;
    font-size: 1em;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 600;
}
.modelkey{
    color: rgb(173, 169, 169);
    margin:2px ;
    padding: 1px;
}
.subtextleft{
    margin-left: 1em;
    font-weight: 600;
}
.subtextright{
    float: right;
    margin-right: 1em;
}
.toggle{
    margin-top: 1em;
    margin-left: 1em;
}
.toggleButon{
    padding: 15px;
    border: none;
    border-radius: 5px 5px 0px 0px;
    background-color: rgb(238, 238, 238);
    color: rgb(0, 0, 0);
    font-size: larger;
}
.flat{
    background-color: transparent;
    border: none;
    color: rgb(115, 116, 116);
    padding: 15px;
}

.flat:hover,
.flat:active{
    background-color: rgb(85, 176, 255);
    color: rgb(56, 57, 57);
}

</style>